import React, { forwardRef, useImperativeHandle, useRef } from "react";

import ExampleCard from "../../components/exampleCard/ExampleCard";
import i1 from '../../aseets/img/ExampleCard/1.png';
import i10 from '../../aseets/img/ExampleCard/10.png';
import i2 from '../../aseets/img/ExampleCard/2.png';
import i3 from '../../aseets/img/ExampleCard/3.png';
import i4 from '../../aseets/img/ExampleCard/4.png';
import i5 from '../../aseets/img/ExampleCard/5.png';
import i6 from '../../aseets/img/ExampleCard/6.png';
import i7 from '../../aseets/img/ExampleCard/7.png';
import i8 from '../../aseets/img/ExampleCard/8.png';
import i9 from '../../aseets/img/ExampleCard/9.png';
import styles from "./Examples.module.css";
import { useTranslation } from 'react-i18next';

const Examples = forwardRef((props, ref) => {
    const { t, i18n } = useTranslation();

    const examples = [
        {
            title: t('examples.examplesList.0.title'),
            img: i1,
        },
        {
            title: t('examples.examplesList.1.title'),
            img: i2,
        },
        {
            title: t('examples.examplesList.2.title'),
            img: i3,
        },
        {
            title: t('examples.examplesList.3.title'),
            img: i4,
        },
        {
            title: t('examples.examplesList.4.title'),
            img: i5,
        },
        {
            title: t('examples.examplesList.5.title'),
            img: i6,
        },
        {
            title: t('examples.examplesList.6.title'),
            img: i7,
        },
        {
            title: t('examples.examplesList.7.title'),
            img: i8,
        },
        {
            title: t('examples.examplesList.8.title'),
            img: i9,
        },
        {
            title: t('examples.examplesList.9.title'),
            img: i10,
        },
    ];

    return (
        <section className={styles.examples}>
            <div className={styles.container}>
                {examples.map((example, index) =>
                    <ExampleCard key={index} img={example.img} title={example.title} number={index + 1} />
                )}
            </div>
            <div ref={ref} className={styles.line} id="cases">{t('examples.line')}</div>
        </section>
    );
});

export default Examples;
