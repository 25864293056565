import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";

import InfoBar from '../../components/infoBar/InfoBar';
import styles from './What.module.css';
import { useTranslation } from 'react-i18next';

// function What() {

const What = forwardRef((props, ref) => {
    const { t, i18n } = useTranslation();

    return (
        <section id='what' ref={ref} className={styles.about}>
            <div className={styles.container}>
                <div className={styles.heading}>
                    <InfoBar text={t('what.what')} />
                    <h1>{t('what.offers')}</h1>
                </div>
                <div className={styles.info}>
                    <p className={`${styles.big} f-20 w-450`}>
                        {t('what.info')}
                    </p>
                    <p className='f-14 w-regular'>
                        {t('what.text1')}
                        <br /><br />
                        {t('what.text2')}
                        <br /><br />
                        {t('what.text3')}
                    </p>
                </div>
            </div>
        </section>
    );
})

export default What;
