import './aseets/fonts/fonts.css'
import './reset.css'
import './global.css'
import './i18n';

import About from './layout/about/About'
import Cases from './layout/cases/Cases'
import CasesInfo from './layout/casesInfo/CasesInfo'
import Contact from './layout/contact/Contact'
import Decor from './layout/decor/Decor'
import Examples from './layout/examples/Examples'
import Footer from './layout/footer/Footer'
import Header from './layout/header/Header'
import WelcomeVideo from './layout/welcomeVideo/WelcomeVideo'
import What from './layout/what/What'
import { useRef } from 'react';

function App() {
  const aboutSectionRef = useRef();
  const whatSectionRef = useRef();
  const casesSectionRef = useRef();
  const contactSectionRef = useRef();
  const portfolioSectionRef = useRef();


  return (
    <div className="App">
      <Header aboutSectionRef={aboutSectionRef} portfolioSectionRef={portfolioSectionRef} whatSectionRef={whatSectionRef} casesSectionRef={casesSectionRef} contactSectionRef={contactSectionRef}></Header>
      <WelcomeVideo></WelcomeVideo>
      <About ref={aboutSectionRef}></About>
      <Cases ref={portfolioSectionRef}></Cases>
      <Decor></Decor>
      <What ref={whatSectionRef}></What>
      <Examples ref={casesSectionRef}></Examples>
      <CasesInfo></CasesInfo>
      <Contact ref={contactSectionRef}></Contact>
      <Footer></Footer>
    </div>
  );
}

export default App;
