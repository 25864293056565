import { useEffect, useState } from "react";

import Button from '../../components/button/Button';
import logo from '../../aseets/img/header-logo.svg';
import styles from './Header.module.css';
import { useTranslation } from 'react-i18next';

function Header({ aboutSectionRef, portfolioSectionRef, whatSectionRef, casesSectionRef, contactSectionRef }) {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [activeSection, setActiveSection] = useState("");
    const [isLangVisible, setIsLangVisible] = useState(false);
    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            if (currentScrollY > lastScrollY && currentScrollY > 100) {
                setIsVisible(false);
            } else {
                setIsVisible(true);
            }

            setLastScrollY(currentScrollY);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollY]);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: "0px",
            threshold: 0.2 // Срабатывает, когда 60% элемента находится в видимой области
        };

        const observerCallback = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setActiveSection(entry.target.id);
                    console.log(entry.target.id);
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        // Наблюдаем за каждым разделом
        if (aboutSectionRef.current) observer.observe(aboutSectionRef.current);
        if (whatSectionRef.current) observer.observe(whatSectionRef.current);
        if (casesSectionRef.current) observer.observe(casesSectionRef.current);
        if (contactSectionRef.current) observer.observe(contactSectionRef.current);

        return () => {
            // Очищаем наблюдатели при размонтировании компонента
            if (aboutSectionRef.current) observer.unobserve(aboutSectionRef.current);
            if (whatSectionRef.current) observer.unobserve(whatSectionRef.current);
            if (casesSectionRef.current) observer.unobserve(casesSectionRef.current);
            if (contactSectionRef.current) observer.unobserve(contactSectionRef.current);
        };
    }, [aboutSectionRef, whatSectionRef, casesSectionRef, contactSectionRef]);

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isSafariBrowser = userAgent.includes("safari") && !userAgent.includes("chrome");
        setIsSafari(isSafariBrowser);
    }, []);

    const scrollToSection = (sectionRef) => {
        sectionRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const changeLanguage = (lang) => {
        setIsLangVisible(!isLangVisible);
        i18n.changeLanguage(lang);
    }

    const supportedLanguages = [
        { code: 'en', label: 'EN' },
        { code: 'de', label: 'DE' },
        { code: 'ua', label: 'UA' }
    ];

    const sortedLanguages = supportedLanguages.sort((a, b) => {
        if (a.code === i18n.language) return -1;
        if (b.code === i18n.language) return 1;
        return 0;
    });

    const isMobile = window.innerWidth <= 768;

    return (
        <header
            id='header'
            className={`${styles.header} ${open ? styles.open : ''} ${isVisible ? styles.visible : styles.hidden}`}
            style={{ minHeight: isMobile && open && (isSafari ? `calc(83vh - 43px)` : `calc(100dvh - 43px)`),
             }}
        >
            <img alt='Ostom logo' src={logo} />
            <ul>
                <li><a onClick={() => scrollToSection(aboutSectionRef)} className={activeSection === 'about' ? styles.active : ''}>{t('header.about')}</a></li>
                <li><a onClick={() => scrollToSection(portfolioSectionRef)} className={activeSection === 'portfolio' ? styles.active : ''}>{t('header.portfolio')}</a></li>
                <li><a onClick={() => scrollToSection(whatSectionRef)} className={activeSection === 'what' ? styles.active : ''}>{t('header.overview')}</a></li>
                <li><a onClick={() => scrollToSection(casesSectionRef)} className={activeSection === 'cases' ? styles.active : ''}>{t('header.cases')}</a></li>
                <li><a onClick={() => scrollToSection(contactSectionRef)} className={activeSection === 'contacts' ? styles.active : ''}>{t('header.contacts')}</a></li>
                <ul className={`${styles.lang} ${isLangVisible ? "" : styles.hidden} f-14 w-regular`} onClick={() => setIsLangVisible(!isLangVisible)}>
                    {sortedLanguages.map(({ code, label }) => (
                        <li key={code} onClick={() => changeLanguage(code)}>{label}</li>
                    ))}
                </ul>
            </ul>
            <Button text={t('header.contactUs')} big onClick={() => scrollToSection(contactSectionRef)} />

            <section className={styles.mobileHeader}>
                <img alt='Ostom logo' src={logo} />
                <section className={styles.burger} onClick={() => setOpen(!open)}>
                    <div></div>
                    <div></div>
                    <div></div>
                </section>
            </section>

            <nav className={styles.mobileNav}>
                <ul className={styles.tabs}>
                    <li>
                        <a
                            onClick={() => {
                                scrollToSection(aboutSectionRef);
                                setOpen(false);
                            }}
                            className={activeSection === 'about' ? styles.active : ''}
                        >
                            {t('header.about')}
                        </a>
                    </li>

                    <li><a onClick={() => { scrollToSection(portfolioSectionRef); setOpen(false) }} className={activeSection === 'portfolio' ? styles.active : ''}>{t('header.portfolio')}</a></li>
                    <li><a onClick={() => { scrollToSection(whatSectionRef); setOpen(false) }} className={activeSection === 'what' ? styles.active : ''}>{t('header.overview')}</a></li>
                    <li><a onClick={() => { scrollToSection(casesSectionRef); setOpen(false) }} className={activeSection === 'cases' ? styles.active : ''}>{t('header.cases')}</a></li>
                    <li><a onClick={() => { scrollToSection(contactSectionRef); setOpen(false) }} className={activeSection === 'contacts' ? styles.active : ''}>{t('header.contacts')}</a></li>
                </ul>
            </nav>

            <section className={styles.languages}>
                    <p onClick={() => { changeLanguage('en'); setOpen(false) }} className='f-20 w-regular'>English</p>
                    <p onClick={() => { changeLanguage('ua'); setOpen(false) }} className='f-20 w-regular'>Українська</p>
                    <p onClick={() => { changeLanguage('de'); setOpen(false) }} className='f-20 w-regular'>Deutsch</p>
            </section>
        </header>
    );
}

export default Header;
