import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";

import Button from "../../components/button/Button";
import InfoBar from "../../components/infoBar/InfoBar";
import logo59 from '../../aseets/img/logo-59.svg';
import styles from "./Contact.module.css";
import { useTranslation } from 'react-i18next';

const Contact = forwardRef((props, ref) => {
    const { t, i18n } = useTranslation();
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isNotificationVisible, setIsNotificationVisible] = useState(false);
    const [requestID, setRequestID] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        request: ''
    });
    const showForm = () => {
        setIsFormVisible(true);
    };

    const hideForm = () => {
        setIsFormVisible(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const getCurrentTime = () => {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');

        return `${day}.${month}.${year} ${hours}:${minutes}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const rID = Math.floor(100000 + Math.random() * 900000);
        setRequestID(rID);


        const dataToSend = {
            ...formData,
            time: getCurrentTime(),
            requestID: rID
        };

        fetch('https://script.google.com/macros/s/AKfycbw2GN6uzhSEQF5Y61nR8q15LLz_l98fb7sthj1bYzuobFpcv9zehtywQTEeuU31BEwxEA/exec', {
            method: 'POST',
            body: JSON.stringify(dataToSend)
        }).then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                setIsNotificationVisible(true);
                setIsFormVisible(false);
            }).catch(error => {
                console.error('Ошибка:', error);
            });
    };

    return (
        <section ref={ref} className={styles.contact} id="contacts">
            <InfoBar text={t('contact.infoBar')}></InfoBar>
            <h1>{t('contact.heading')}</h1>
            <p className="f-20 w-regular">{t('contact.description')}</p>
            <Button text={t('contact.buttonText')} big onClick={showForm}></Button>

            <div className={`${styles.overlay} ${isFormVisible ? styles.visible : ''}`} onClick={hideForm}></div>
            <form className={`${isFormVisible ? styles.visible : ''}`} onSubmit={handleSubmit}>
                <img width="59" height="59" src={logo59} alt="Ostom logo"></img>
                <h3 className="f-48 w-450">{t('contact.form.greeting')}</h3>
                <p className="f-20 w-regular">{t('contact.form.instruction')}</p>
                <div className={styles.inputsContainer}>
                    <div className={styles.input}>
                        <label htmlFor="name" className="f-14 w-450">{t('contact.form.fields.name.label')}</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="f-14 w-regular"
                            placeholder={t('contact.form.fields.name.placeholder')}
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className={styles.input}>
                        <label htmlFor="email" className="f-14 w-450">{t('contact.form.fields.email.label')}</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="f-14 w-regular"
                            placeholder={t('contact.form.fields.email.placeholder')}
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className={styles.input}>
                    <label htmlFor="request" className="f-14 w-450">{t('contact.form.fields.request.label')}</label>
                    <textarea
                        id="request"
                        name="request"
                        className="f-14 w-regular"
                        placeholder={t('contact.form.fields.request.placeholder')}
                        value={formData.request}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.buttonContainer}>
                    <Button text={t('contact.form.confirmButtonText')} onClick={handleSubmit}></Button>
                </div>
            </form>

            <div className={`${styles.overlay} ${isNotificationVisible ? styles.visible : ''}`} onClick={() => setIsNotificationVisible(false)}></div>
            <div className={`${styles.notification} ${isNotificationVisible ? styles.visible : ''}`}>
                <img width="59" height="59" src={logo59} alt="Ostom logo"></img>
                <p className="f-20 w-regular">{t('contact.notification.successMessage')}</p>
                <p className="f-16 w-regular">{t('contact.notification.requestIdLabel')}{requestID}</p>
            </div>
        </section>

    );
});

export default Contact;
