import React, { useState } from "react";
import { forwardRef, useImperativeHandle, useRef } from "react";

import CaseCard from "../../components/caseCard/CaseCard";
import bg1 from '../../aseets/img/CaseCard/bg-1.svg'
import bg2 from '../../aseets/img/CaseCard/bg-2.svg'
import bg3 from '../../aseets/img/CaseCard/bg-3.svg'
import bg4 from '../../aseets/img/CaseCard/bg-4.svg'
import bg5 from '../../aseets/img/CaseCard/bg-5.svg'
import styles from "./Cases.module.css";
import { useSwipeable } from "react-swipeable";
import { useTranslation } from 'react-i18next';

const Cases = forwardRef((props, ref) => {
    const { t, i18n } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(0);
    const totalCards = 5;

    const cases = [
        {
            title: t('cases.casesList.0.title'),
            hours: t('cases.casesList.0.hours'),
            money: t('cases.casesList.0.money'),
            months: t('cases.casesList.0.months'),
            bg: bg1,
            info: t('cases.casesList.0.info', { returnObjects: true })
        },
        {
            title: t('cases.casesList.1.title'),
            hours: t('cases.casesList.1.hours'),
            money: t('cases.casesList.1.money'),
            months: t('cases.casesList.1.months'),
            bg: bg2,
            info: t('cases.casesList.1.info', { returnObjects: true })         
        },
        {
            title: t('cases.casesList.2.title'),
            hours: t('cases.casesList.2.hours'),
            money: t('cases.casesList.2.money'),
            months: t('cases.casesList.2.months'),
            bg: bg3,
            info: t('cases.casesList.2.info', { returnObjects: true })           
        },
        {
            title: t('cases.casesList.3.title'),
            hours: t('cases.casesList.3.hours'),
            money: t('cases.casesList.3.money'),
            months: t('cases.casesList.3.months'),
            bg: bg4,
            info: t('cases.casesList.3.info', { returnObjects: true })          
        },
        {
            title: t('cases.casesList.4.title'),
            hours: t('cases.casesList.4.hours'),
            money: t('cases.casesList.4.money'),
            months: t('cases.casesList.4.months'),
            bg: bg5,
            info: t('cases.casesList.4.info', { returnObjects: true })         
        },
    ]

    const handleSwipe = (direction) => {
        if (direction === "LEFT") {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % totalCards);
        } else if (direction === "RIGHT") {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + totalCards) % totalCards);
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => handleSwipe("LEFT"),
        onSwipedRight: () => handleSwipe("RIGHT"),
    });

    const isMobile = () => window.innerWidth <= 768;

    return (
        <section className={styles.contact} ref={ref} id='portfolio'>
            <div className={styles.heading}>
                <h1>{t('cases.title')}</h1>
            </div>
            <div className={`${styles.galleryWrapper} ${styles.mobileOnly}`} {...handlers}>
                <div
                    className={styles.caseCards}
                    style={
                        isMobile()
                            ? {
                                transform: `translateX(${(-currentIndex + 2) * 100}%)`,
                                transition: "transform 0.5s ease-in-out",
                            }
                            : {}
                    }
                >
                    {Array.from({ length: totalCards }).map((_, index) => (
                        <div className={styles.cardWrapper} key={index}>
                            <CaseCard number={index + 1} case={cases[index]} />
                        </div>
                    ))}
                </div>
            </div>

            <div className={`${styles.indicatorWrapper} ${styles.mobileOnly}`}>
                <div className={styles.indicatorLine}>
                    {Array.from({ length: totalCards }).map((_, index) => (
                        <div
                            key={index}
                            className={`${styles.indicatorSegment} ${currentIndex === index ? styles.active : ""
                                }`}
                        ></div>
                    ))}
                </div>
            </div>
        </section>
    );
})

export default Cases;
