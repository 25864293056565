import React, { useState } from "react";

import f from '../../aseets/img/Footer/f.svg'
import i from '../../aseets/img/Footer/i.svg'
import l from '../../aseets/img/Footer/l.svg'
import logo from '../../aseets/img/header-logo.svg'
import styles from './Footer.module.css'
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t, i18n } = useTranslation();

    const [isprivacyVisible, setIsPrivacyVisible] = useState(false);
    const [istermsVisible, setIsTermsVisible] = useState(false);
    const [iscookiesVisible, setIsCookiesVisible] = useState(false);
    return (
        <footer className={styles.footer}>
            <div className={styles.top}>
                <div className={styles.icons}>
                    <a href="#header">
                        <img src={f} alt="Facebook link icon"></img>
                    </a>
                    <a href="#header">
                        <img src={l} alt="LinkedIn link icon"></img>
                    </a>
                    <a href="#header">
                        <img src={i} alt="Instagram link icon"></img>
                    </a>
                </div>
                <img src={logo} alt="Ostom logo" className={styles.logo}></img>
            </div>
            <div className={styles.bottom}>
                <p className="f-14 w-regular">Copyright <span>©</span> 2024 by Ostom</p>
                <ul>
                    <li><p className="f-14 w-regular" onClick={() => setIsPrivacyVisible(true)}>{t('footer.privacy')}</p></li>
                    <li><p className="f-14 w-regular" href="#" onClick={() => setIsTermsVisible(true)}>{t('footer.terms')}</p></li>
                    <li><p className="f-14 w-regular" href="#" onClick={() => setIsCookiesVisible(true)}>{t('footer.cookies')}</p></li>
                </ul>
            </div>

            <div className={`${styles.overlay} ${isprivacyVisible ? styles.visible : ''}`} onClick={() => setIsPrivacyVisible(false)}></div>
            <div className={`${styles.notification} ${isprivacyVisible ? styles.visible : ''}`}>
                <p className="f-20 w-regular">{t('footer.privacy')}</p>
                <p className="f-14 w-regular">{t('footer.pr1')}</p>
            </div>

            <div className={`${styles.overlay} ${istermsVisible ? styles.visible : ''}`} onClick={() => setIsTermsVisible(false)}></div>
            <div className={`${styles.notification} ${istermsVisible ? styles.visible : ''}`}>
                <p className="f-20 w-regular">{t('footer.terms')}</p>
                <p className="f-14 w-regular">{t('footer.terms1')}</p>
            </div>

            <div className={`${styles.overlay} ${iscookiesVisible ? styles.visible : ''}`} onClick={() => setIsCookiesVisible(false)}></div>
            <div className={`${styles.notification} ${iscookiesVisible ? styles.visible : ''}`}>
                <p className="f-20 w-regular">{t('footer.cookies')}</p>
                <p className="f-14 w-regular">{t('footer.cook1')}</p>
            </div>
        </footer>
    )
}

export default Footer