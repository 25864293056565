import './CaseCard.css'

import React, {useState} from "react";

import Button from '../button/Button.jsx'
import img1 from '../../aseets/img/CaseCard/1.png'
import img2 from '../../aseets/img/CaseCard/2.png'
import img3 from '../../aseets/img/CaseCard/3.png'
import { useTranslation } from 'react-i18next';

function CaseCard(props) {
    const { t, i18n } = useTranslation();
    const [isHovered, setIsHovered] = useState(false);

    const handleToggleHover = () => {
        setIsHovered((prev) => !prev);
    };

    const background = {
        backgroundImage: `url(${props.case.bg})`,
    };

    return (
        <div
            className='UI_caseCard_wrapper'
            onClick={handleToggleHover}
        >
            <div className='number_wrapper'>
                <div className='number f-14'>{props.number}</div>
                <p className='f-14'>{t('caseCard.case')}</p>
            </div>

            <div className='card' style={background}>
                <p className='f-20 ml-15 pr-30 m-h-48'>{props.case.title}</p>
                <div className='icons'>
                    <div className='icon'>
                        <img width='54' height='48' src={img1} alt='clock icon'></img>
                        <p className='f-16'>{props.case.hours}</p>
                        <p className='desc f-14'>{t('caseCard.month')}</p>
                    </div>
                    <div className='icon'>
                        <img width='51' height='51' src={img2} alt='money icon'></img>
                        <p className='f-16'>{props.case.money}</p>
                        <p className='desc f-14'>{t('caseCard.year')}</p>
                    </div>
                    <div className='icon'>
                        <img width='47' height='47' src={img3} alt='calendar icon'></img>
                        <p className='f-16'>{props.case.months}</p>
                        <p className='desc f-14'>{t('caseCard.implementation')}</p>
                    </div>
                </div>

                <div
                    className={`hover-content ${isHovered ? 'visible' : ''}`}
                >
                    <ul>
                        {props.case.info.map((challenge, index) => (
                            <li className='f-14 w-regular' key={index}>
                                {challenge}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default CaseCard