import { forwardRef, useImperativeHandle, useRef } from "react";

import InfoBar from '../../components/infoBar/InfoBar';
import logo from '../../aseets/img/logo-66.svg'
import styles from './About.module.css'
import { useTranslation } from 'react-i18next';
import v from '../../aseets/welcome.mp4';

const About = forwardRef((props, ref) => {
    const { t, i18n } = useTranslation();

    return (
        <section id='about' ref={ref} className={styles.about}>
            <div className={styles.container}>
                <div className={styles.heading}>
                    <InfoBar text={t('about.infoBar')} />
                    <h1>{t('about.title')}</h1>
                    <img className='logo-66' alt='Ostom logo' src={logo}></img>
                </div>
                <div className={styles.info}>
                    <p className='f-20 w-450'>{t('about.description1')}</p>
                    <p className='f-14 w-regular'>{t('about.description2')}</p>
                </div>
            </div>
        </section>

    );
});

export default About;
