import React, { useEffect, useRef, useState } from "react";

import CaseInfo from "../../components/caseInfo/CaseInfo";
import bg1 from '../../aseets/img/Cases/1.png';
import bg2 from '../../aseets/img/Cases/2.png';
import bg3 from '../../aseets/img/Cases/3.png';
import styles from "./CasesInfo.module.css";
import { useSwipeable } from "react-swipeable";
import { useTranslation } from 'react-i18next';

function Contact() {
    const { t, i18n } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(1);
    const totalCards = 3;
    const cardRefs = useRef([]);

    const handleSwipe = (direction) => {
        if (direction === "LEFT") {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % totalCards);
        } else if (direction === "RIGHT") {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + totalCards) % totalCards);
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => handleSwipe("LEFT"),
        onSwipedRight: () => handleSwipe("RIGHT"),
    });

    useEffect(() => {
       if (window.innerWidth <= 768) return
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(styles.visible);
                    } else {
                        entry.target.classList.remove(styles.visible);
                    }
                });
            },
            { threshold: 0.9 } // Срабатывает, когда 50% карточки в зоне видимости
        );

        cardRefs.current.forEach((card) => {
            if (card) observer.observe(card);
        });

        return () => {
            cardRefs.current.forEach((card) => {
                if (card) observer.unobserve(card);
            });
        };
    }, []);
    // Function to determine if it is a mobile view
    const isMobile = () => window.innerWidth <= 768;

    const data = [
        {
            bg: bg1,
            title: t('casesInfo.casesList.0.title'),
            description: t('casesInfo.casesList.0.description'),
            challenges: t('casesInfo.casesList.0.challenges', { returnObjects: true }),
            solutions: t('casesInfo.casesList.0.solutions', { returnObjects: true }),
            hours: t('casesInfo.casesList.0.hours'),
            money: t('casesInfo.casesList.0.money'),
            months: t('casesInfo.casesList.0.months')
        },
        {
            bg: bg2,
            title: t('casesInfo.casesList.1.title'),
            description: t('casesInfo.casesList.1.description'),
            challenges: t('casesInfo.casesList.1.challenges', { returnObjects: true }),
            solutions: t('casesInfo.casesList.1.solutions', { returnObjects: true }),
            hours: t('casesInfo.casesList.1.hours'),
            money: t('casesInfo.casesList.1.money'),
            months: t('casesInfo.casesList.1.months')
        },
        {
            bg: bg3,
            title: t('casesInfo.casesList.2.title'),
            description: t('casesInfo.casesList.2.description'),
            challenges: t('casesInfo.casesList.2.challenges', { returnObjects: true }),
            solutions: t('casesInfo.casesList.2.solutions', { returnObjects: true }),
            hours: t('casesInfo.casesList.2.hours'),
            money: t('casesInfo.casesList.2.money'),
            months: t('casesInfo.casesList.2.months')
        },
    ]

    return (
        <section className={styles.cases}>
            <div className={`${styles.galleryWrapper}`} {...handlers}>
                <div className={styles.caseCards} style={
                    isMobile()
                        ? {
                            transform: `translateX(${(-currentIndex + 1) * 100}%)`,
                            transition: "transform 0.5s ease-in-out",
                        }
                        : {}
                }>
                    {data.map((item, index) => (
                        <div
                            ref={(el) => (cardRefs.current[index] = el)}
                            className={`${styles.cardWrapper} ${styles.hidden}`}
                            key={index}
                        >
                            <CaseInfo props={item} number={index + 1} />
                        </div>
                    ))}
                </div>

                <div className={`${styles.indicatorWrapper} ${styles.mobileOnly}`}>
                    <div className={styles.indicatorLine}>
                        {Array.from({ length: totalCards }).map((_, index) => (
                            <div
                                key={index}
                                className={`${styles.indicatorSegment} ${currentIndex === index ? styles.active : ""
                                    }`}
                            ></div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;