import styles from './WelcomeVideo.module.css'
import v from '../../aseets/welcome.mp4';

function About() {
    const isMobile = window.innerWidth <= 768;
    return (
        <>
            {!isMobile && <video
                className={styles.fullWidthVideo}
                src={v}
                muted
                autoPlay
                loop
            />}
        </>

    );
};

export default About;
