import React, { useEffect, useRef, useState } from "react";

import styles from "./Decor.module.css";
import { useTranslation } from 'react-i18next';
import v from '../../aseets/video.mp4';

function Decor() {
    const { t, i18n } = useTranslation();
    const videoRef = useRef(null);
    const [isVideoVisible, setIsVideoVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVideoVisible(true);
                    } else {
                        setIsVideoVisible(false);
                    }
                });
            },
            { threshold: 0.5 }
        );

        if (videoRef.current) {
            observer.observe(videoRef.current);
        }

        return () => {
            if (videoRef.current) {
                observer.unobserve(videoRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (isVideoVisible && videoRef.current) {
            videoRef.current.play();
        } else if (videoRef.current) {
            videoRef.current.pause();
        }
    }, [isVideoVisible]);

    const isMobile = window.innerWidth <= 768;

    return (
        <>
            {!isMobile && <div className={styles.decor}>
                <video
                    ref={videoRef}
                    className={styles.fullWidthVideo}
                    src={v}
                    muted
                    playsInline
                    loop
                />
            </div>
            }
        </>
    );
}

export default Decor;